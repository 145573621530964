import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/domains": [~3],
		"/domains/[domain]": [~4],
		"/domains/[domain]/delete": [~5],
		"/domains/[domain]/edit": [~6],
		"/login": [~7],
		"/maintainers": [~8],
		"/maintainers/[domain]": [~9],
		"/maintainers/[domain]/edit": [~10],
		"/register": [~11],
		"/sentry-example": [12],
		"/smtp": [~13]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';